import React from "react"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import rond_color from "../assets/rond_color.svg"
// import { Link } from "gatsby"

import Navigation from "../components/Navigation"


const goodPerson = () => (
    <div
        className="containerManifesto"
      >
        <div className=" container containerHome">
        <Navigation/>
        {/*<div className="rondTache row">
          <img src={rond} className="col-3 rond"/>
          <img src={tache} className="col-9 tache"/>
        </div>*/}
    <div>
    <SEO title="Plaiz - You are a good person - Plaiz, fashion social network"/>
    <div>
    <h1 className="p1" style={{color:"white", marginTop:100}}>You are a good person ✨</h1>

    <h4
      style={styles.backToHomeLink}
    >
    <p className="textChangeGameLeft"><br/>
          <b></b>
            <br/>
            By downloading Plaiz, you contribute to a better world ! Our goal is to help 1 billion people live their passion.
            <br/><br/>
            We pioneered the movement to remove like-counts so that you don't feel pressured to look a certain way, so that you can express yourself freely, and in a more authentic way.
            <br/><br/>
            This started our revolution, and since then, we made tremendous efforts to keep pushing Plaiz in that direction.
            <br/><br/>
            For example, we hid post dates because we don't want our users to be feel like they are newbies on the platform. You can upload as many pictures are you want to create your universe from the first day. Everyone is equality treated.
            <br/><br/>
            {/* Nous avons 3 types de réactions car les utilisateurs qui postent du contenu peuvent connaître le nombre de réactions qu'ils ont obtenu, en guise d'avis (uniquement positif). Le fait d'avoir 3 réactions permet de se décharger de la charge mentale qui nous pèse lorsqu'on compare le nombre de likes qu'on a eu sur des photos. Vu qu'il est difficile de savoir si 3👏, 5💚 et 8🔥 valent mieux que 5👏, 10💚 et 3🔥, nos utilisateurs choisissent de ne pas faire le calcul, et postent sans se poser la question.
            <br/><br/>
            Nous sommes conscients que ces procédés vont ralentir notre croissance et réduire le temps passé sur l'application vu que nous ne jouons pas sur les aspect psychologique utilisés par les autres réseaux. Mais nous préférons faire le bien. Et c'est pour cela que nous sommes encore une société indépendante, tant que nous n'aurons pas de partenaires qui partagent nos valeurs, et que nous ne monétisons pas pour l'instant la plateforme.
            <br/><br/>
            Nous rendons publiques ces informations dans l'espoir d'inspirer d'autres plateformes, c'est comme cela que nous impacterons 1 billion de personnes. #TeamGameChanger
            <br/><br/>
            Pour le reste, nous vous laissons découvrir nos autres surprises sur l'application ! Surtout, amusez-vous bien !
            <br/><br/>
            (Et si vous êtes arrivés jusqu'ici, dites-le nous :) hello@plaiz.io)" */}
            [This is a shortened version of this text, to access the full version, send us an email : hello@plaiz.io]
            <br/><br/><br/><br/><br/>

        </p>
        
    </h4>
    </div>
    </div>
    </div>
    </div>
)

export default goodPerson

const styles = {
  backToHomeLink: {
    color: "white",
  },
}